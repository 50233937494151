<template>
    <div style="margin-left: 1.5rem; margin-right: 1.5rem">
        <div style="background-color: #f0f0f7">
            <p style="margin-left: 4px; margin-top: 22px" class="headertext">
                {{ $t("businesstypereport") }}
            </p>
        </div>
        <v-card class="dialogmargin card pb-4" style="margin-bottom: 5rem">
            <v-row class="pl-md-0 mt-0" style="margin-left: 15px; margin-right: 10px">
                <v-col cols="12" xs="12" sm="6" md="3" lg="2">
                    <p class="mt-0">{{ $t("start_date") }}</p>
                    <date-picker v-bind:clearable="false" v-model="chartStartDate" format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY" :disabled-date="(date) =>
                            date > new Date()
                            " class="fromdate custom-vuedatepicker2" style="width: 150px" @change="OnChangeStartDate()">
                        <!-- <template slot="icon-calendar">
                            <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
                        </template> -->
                    </date-picker>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="2">
                    <p class="mt-0">{{ $t("enddata") }}</p>
                    <date-picker v-bind:clearable="false" class="todate custom-vuedatepicker2" v-model="chartEndDate" format="DD/MM/YYYY"
                        width="100%" placeholder="DD/MM/YYYY" :disabled-date="(date) =>
                            date < BeforeStartDate()
                            " readonly style="width: 150px" @change="OnChangeEndDate()">
                        <!-- <template slot="icon-calendar">
                            <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
                        </template> -->
                    </date-picker>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
                    <v-autocomplete :items="exportItem" item-text="exptxt" item-value="expval" color="#F99D20"
                        class="movelf ddl" outlined v-model="expit" :placeholder="$t(`Export`)" style="
                        min-width: 150px;
                        max-width: 150px;
                        margin-top: 2rem;
                    " dense v-on:change="changetoexport(expit)">
                        <v-icon slot="append" class="v-icon notranslate mdi mdi-chevron-down theme--light" />
                    </v-autocomplete>
                </v-col>
            </v-row>

            <!-- the chart come here -->
            <v-row class="backGG" justify="center">
                <div style="width: 100%">
                    <apexchart type="bar" :options="chartOptions" :series="computedChartSeries" height="1000"></apexchart>
                </div>

            </v-row>

            <!-- the table are here -->
            <div class="backGG mt-5 mb-7" justify="center">
                <v-row style="width:100%">
                    <v-text-field autocomplete="off" v-model="searchtxt"
                        prepend-inner-icon="mdi-magnify" outlined dense
                        style="margin-top: 2rem; margin-left: 3rem; margin-right: 2rem; max-width: 350px;"
                        color="#f99d20" :placeholder="$t('Search by Business Type')"></v-text-field>
                
                </v-row>
                <v-row>
                    <v-col cols="12" lg="4" md="5" sm="12" xs="12">
                        <div class="mt-5">
                            <v-data-table
                                style="margin-left: 2rem; margin-right: 2rem; height:287px; overflow: auto; box-shadow: 0px 2px 6px #00000029; scrollbar-width: none;"
                                :items="allBusinessList" :items-per-page="30" :headers="headers" :search="searchtxt"
                                fixed-header hide-default-header hide-default-footer :mobile-breakpoint="0">
                                <template v-slot:header="{ props: {} }">
                                    <thead>
                                        <tr>
                                            <th @click="sortFun('businessTypeName')">
                                                {{ $t("Businesstype") }}
                                                <v-icon small v-if="!businessTypeName">mdi-arrow-down</v-icon>
                                                <v-icon small v-if="businessTypeName">mdi-arrow-up</v-icon>
                                            </th>
                                            <th @click="sortFun('quantity')">
                                                {{ $t("quantity") }}
                                                <v-icon small v-if="!quantity">mdi-arrow-down</v-icon>
                                                <v-icon small v-if="quantity">mdi-arrow-up</v-icon>
                                            </th>
                                        </tr>
                                    </thead>
                                </template>
                                <template v-slot:item="{ item }">
                                    <tr @click="oneClick(item)">
                                        <td>{{ item.businessTypeName }}</td>
                                        <td>{{ item.quantity }}</td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="8" md="7" sm="12" xs="12">
                        <v-data-table style="margin-left: 2rem; margin-right: 2rem; box-shadow: 0px 2px 6px #00000029;"
                            class="mt-5" :items="companyBusinessList" hide-default-footer hide-default-header
                            :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event"
                            :mobile-breakpoint="0">
                            <template v-slot:header="{ props: {} }">
                                <thead>
                                    <tr>
                                        <th>{{ $t("no") }}</th>
                                        <th @click="sortFun1('businessTypeName')">
                                            {{ $t("Businesstype") }}
                                            <v-icon small v-if="!businessTypeName">mdi-arrow-down</v-icon>
                                            <v-icon small v-if="businessTypeName">mdi-arrow-up</v-icon>
                                        </th>
                                        <th @click="sortFun1('companyName')">
                                            {{ $t("companyName") }}
                                            <v-icon small v-if="!companyName">mdi-arrow-down</v-icon>
                                            <v-icon small v-if="companyName">mdi-arrow-up</v-icon>
                                        </th>
                                        <th @click="sortFun1('register_Date')">
                                            {{ $t("registerDate") }}
                                            <v-icon small v-if="!register_Date">mdi-arrow-down</v-icon>
                                            <v-icon small v-if="register_Date">mdi-arrow-up</v-icon>
                                        </th>
                                        <th @click="sortFun1('companySize')">
                                            {{ $t("companysize") }}
                                            <v-icon small v-if="!companySize">mdi-arrow-down</v-icon>
                                            <v-icon small v-if="companySize">mdi-arrow-up</v-icon>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.no }}</td>
                                    <td>{{ item.businessTypeName }}</td>
                                    <td>{{ item.companyName }}</td>
                                    <td>{{ DateFormat(item.register_Date) }}</td>
                                    <td>{{ item.companySize }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                        <div class="text-right">
                            <v-row justify="end" class="ml-5 mt-5 mr-5">
                                <v-col cols="12" md="5">
                                    <v-pagination :total-visible="6" v-model="page" :length="pageCount" color="#f99d20"></v-pagination>
                                </v-col>
                                <v-col cols="12" md="2" class="mt-1">
                                    <v-select dense style="width: 120px; float: right" solo 
                                        :label="`${itemsPerPage}`"
                                        v-model="itemsPerPage" :items="items"
                                        @input="itemsPerPage = parseInt($event, 10)"></v-select>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-card>

        <!-- print dialog -->
        <v-dialog v-model="printDialog" max-width="100%">
            <v-card style="min-width: 100%">
                <div class="canvas_div_pdf">
                    <v-card-text class="print-break-page" ref="content">
                        <div style="margin-left: 1rem">
                            <v-row class="pl-md-0 mt-0 pt-2">
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                                    <p class="mt-0 mb-1 ml-1">{{ DateFormatonTop(todaydate) }}</p>
                                    <p class="headtext headertext">
                                        {{ $t("reportsummary") }}
                                    </p>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col class="mr-0 pr-0" cols="3" xs="6" sm="2" md="1" lg="1" xl="1">
                                    <v-img class="ggg" style="padding: -10px; padding-right: 0px" :src="logo.image"
                                        max-height="60" max-width="60" min-width="60" min-height="60" contain />
                                </v-col>
                                <v-col style="min-width: 150px" cols="4" xs="6" sm="1" md="1" lg="1" xl="1"
                                    class="mt-2 ml-0 pl-0">
                                    <p style="
                                        padding-left: 0px;
                                        margin-bottom: 0px;
                                        font-size: 18px;
                                        color: #0fa7d8 !important;
                                    ">
                                        Optimistic
                                    </p>
                                    <p style="
                                        padding-left: 0px;
                                        margin-bottom: -5px;
                                        font-size: 12px;
                                        color: #f99d20 !important;
                                    ">
                                        Powered by Optimistic
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row class="pl-md-0 mt-0" style="margin-right: 10px">
                                <v-col class="mt-0" cols="12" xs="12" sm="6" md="3" lg="2">
                                    <p class="mt-0 mb-0">{{ $t("Dates") }}</p>
                                    <p class="headtext">
                                        {{ DateFormatonTopbet(chartStartDate) }} -
                                        {{ DateFormatonTopbet(chartEndDate) }}
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row class="backGGpdf mt-4" justify="center">
                            <div style="width: 100%; border: 2px solid #00000029; border-radius: 15px;">
                                <apexchart type="bar" :options="chartOptions" :series="computedChartSeries" height="1000">
                                </apexchart>
                            </div>
                        </v-row>
                        <div v-for="item in theralList" :key="item.businessTypeID">
                            <p class="headtext ml-5 mt-5" style="font-size: 24px; font-family: 'Kanit', sans-serif">
                                {{ $t("Businesstype") }} : {{ item.businessTypeName }} ({{ item.companyList.length }})
                            </p>
                            <div id="middlecol" class="pt-2 pb-5">
                                <v-data-table
                                    style="margin-top: 15px; margin-left: 2rem; margin-right: 2rem; border: 2px solid #00000029;"
                                    :items="item.companyList" hide-default-footer hide-default-header
                                    :items-per-page="itemsPerPage1">
                                    <template v-slot:header="{ props: {} }">
                                        <thead>
                                            <tr>
                                                <th>{{ $t("no") }}</th>
                                                <th>{{ $t("Businesstype") }}</th>
                                                <th>{{ $t("companyName") }}</th>
                                                <th>{{ $t("registerDate") }}</th>
                                                <th>{{ $t("companysize") }}</th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td>{{ item.no }}</td>
                                            <td>{{ item.businessTypeName }}</td>
                                            <td>{{ item.companyName }}</td>
                                            <td>{{ DateFormat(item.register_Date) }}</td>
                                            <td>{{ item.companySize }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                    </v-card-text>
                </div>
                <v-card-actions class="pb-6">
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="150" max-height="35" text @click="
                        printDialog = false;
                    expit = '';
                    ">
                        {{ $t("cancel") }}
                    </v-btn>
                    <v-btn color="#F99D20" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35"
                        style="font-family: kanit, Regular; font-weight: normal" @click="downloadPDF()">
                        {{ $t("download") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Loading Dialog -->
        <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

    </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import VueApexCharts from 'vue-apexcharts';
import DatePicker from "vue2-datepicker";
import axios from "axios";
import moment from "moment";
import enurl from "@/api/environment";
import "vue2-datepicker/index.css";
import XLSX from "xlsx";
import $ from "jquery";
import LoadingDialog from "@/components/Loading.vue";
export default {
    components: {
        Apexchart: VueApexCharts,
        DatePicker,
        LoadingDialog,
    },
    data() {
        return {
            permission: {
                export: true,
            },
            searchtxt: null,
            url: enurl.apiUrl,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            itemsPerPage1: 500,
            items: [10, 20, 30, 50],
            LoadingDialog: false,
            chartStartDate: null,
            chartEndDate: null,
            todaydate: new Date(),
            expit: "",
            sheetTitle: null,
            exportItem: [
                { expval: 1, exptxt: "Excel" },
                { expval: 2, exptxt: "PDF" },
            ],
            printDialog: false,
            allBusinessList: [],
            companyBusinessList: [],
            businessTypeName: null,
            companySize: null,
            quantity: null,
            register_Date: null,
            companyName: null,
            logo: {
                image: require("@/assets/images/logo@2x.png"),
            },
            chartOptions: {
                chart: {
                    type: 'bar',
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: [
                        this.$t("Other"),
                        this.$t("Transport"),
                        this.$t("Tourism"),
                        this.$t("Service"),
                        this.$t("RentalCompany"),
                        this.$t("Association"),
                        this.$t("Dormitory"),
                        this.$t("Carcage"),
                        this.$t("Carshow"),
                        this.$t("Personalcare"),
                        this.$t("Outsourcing"),
                        this.$t("Insurance"),
                        this.$t("Finance"),
                        this.$t("Marketcom"),
                        this.$t("Organizercom"),
                        this.$t("Accounting"),
                        this.$t("techIT"),
                        this.$t("School"),
                        this.$t("Clinic"),
                        this.$t("Hospital"),
                        this.$t("Transportcom"),
                        this.$t("Construction"),
                        this.$t("Securitycom"),
                        this.$t("Gas"),
                        this.$t("Merchandise"),
                        this.$t("Office"),
                        this.$t("Factory"),
                        this.$t("Restaurant"),
                        this.$t("Hotel / Resort"),
                        this.$t("Individual"),
                    ],
                    labels: {
                        style: {
                            width: '150px',
                        },
                    },
                },
                yaxis: {

                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + ' company';
                        },
                    },
                },
                colors: ['#F99D20']
            },
            chartSeries: [
                {
                    name: 'Total',
                    data: [],
                },
            ],
            delay: 700,
            clicks: 0,
            timer: null,
            theralList: [],
        };
    },
    computed: {
        computedChartSeries() {
            return this.chartSeries.map(series => ({ ...series }));
        },
        headers() {
            return [
                {
                    text: this.$t("Businesstype"),
                    value: "businessTypeName",
                    align: "left",
                    sortable: true,
                },
                {
                    text: this.$t("quantity"),
                    value: "quantity1",
                    align: "left",
                    sortable: true,
                },
            ];
        },
        label() {
            return [
                this.$t("Other"),
                this.$t("Transport"),
                this.$t("Tourism"),
                this.$t("Service"),
                this.$t("RentalCompany"),
                this.$t("Association"),
                this.$t("Dormitory"),
                this.$t("Carcage"),
                this.$t("Carshow"),
                this.$t("Personalcare"),
                this.$t("Outsourcing"),
                this.$t("Insurance"),
                this.$t("Finance"),
                this.$t("Marketcom"),
                this.$t("Organizercom"),
                this.$t("Accounting"),
                this.$t("techIT"),
                this.$t("School"),
                this.$t("Clinic"),
                this.$t("Hospital"),
                this.$t("Transportcom"),
                this.$t("Construction"),
                this.$t("Securitycom"),
                this.$t("Gas"),
                this.$t("Merchandise"),
                this.$t("Office"),
                this.$t("Factory"),
                this.$t("Restaurant"),
                this.$t("Hotel / Resort"),
                this.$t("Individual"),
            ];
        },
    },
    watch: {
        chartStartDate() {
            if (this.chartStartDate == null && this.chartEndDate == null) {
                this.getthechart();
            }
        },
        chartEndDate() {
            if (this.chartStartDate == null && this.chartEndDate == null) {
                this.getthechart();
            }
        },
    },
    mounted() {
        this.getthechart();
        this.chartData;
    },
    methods: {
        oneClick: function (e) {
            this.clicks++
            if (this.clicks === 1) {
                var self = this
                this.timer = setTimeout(function () {
                    self.clicks = 0
                }, this.delay);
            } else {
                clearTimeout(this.timer);
                this.companyBusinessList = [];
                let num = 1;
                if (e.companyList != null) {
                    for (let t = 0; t < e.companyList.length; t++) {

                        this.companyBusinessList.push({
                            ...e.companyList[t],
                            no: num
                        })
                        num++;
                    }
                }
                this.theralList.push(e);
                this.clicks = 0;
            }
        },
        BeforeStartDate() {
            let today = new Date();
            let val = new Date(this.chartStartDate);
            let fromdate = this.chartStartDate.getDate();
            if (fromdate == today.getDate()) {
                let d = new Date(val.setDate(val.getDate()));
                return d;
            } else {
                let d = new Date(val.setDate(val.getDate()));
                return d;
            }
        },
        OnChangeStartDate() {
            let self = this;
            if (self.chartEndDate != null) {
                if (
                    moment(self.chartStartDate) < moment(self.chartEndDate).add(1, "day")
                ) {
                    self.getthechart();
                }
                // else {
                //     alert("Start date can’t be after end date.");
                // }
            }

        },
        OnChangeEndDate() {
            let self = this;
            if (self.chartStartDate != null) {
                if (
                    moment(self.chartStartDate) < moment(self.chartEndDate).add(1, "day")
                ) {
                    self.getthechart();
                }
                // else {
                //     alert("Start date can’t be after end date.");
                // }
            }

        },
        sortFun(rowName) {
            let self = this;
            let keyName = [
                "businessTypeName",
                "quantity",
            ];
            if (
                rowName == "businessTypeName"
            ) {
                self.allBusinessList = self.allBusinessList.sort((a, b) =>
                    self[rowName]
                        ? b[rowName].localeCompare(a[rowName])
                        : a[rowName].localeCompare(b[rowName])
                );
            } else {
                self.allBusinessList = self.allBusinessList.sort((a, b) =>
                    self[rowName] ? b[rowName] - a[rowName] : a[rowName] - b[rowName]
                );
            }
            for (let x of keyName) {
                x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
            }
        },
        sortFun1(rowName) {
            let self = this;
            let keyName = [
                "businessTypeName",
                "companyName",
                "register_Date",
                "companySize"
            ];
            if (
                rowName == "businessTypeName" ||
                rowName == "companyName" ||
                rowName == "register_Date"
            ) {
                self.companyBusinessList = self.companyBusinessList.sort((a, b) =>
                    self[rowName]
                        ? b[rowName].localeCompare(a[rowName])
                        : a[rowName].localeCompare(b[rowName])
                );
            } else {
                self.companyBusinessList = self.companyBusinessList.sort((a, b) =>
                    self[rowName] ? b[rowName] - a[rowName] : a[rowName] - b[rowName]
                );
            }
            for (let x of keyName) {
                x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
            }
        },
        getthechart() {
            try {
                let self = this;
                self.companyBusinessList = [];
                self.theralList = [];
                let dshow = [];
                let startyear = null;
                let endyear = null;
                self.expit = "";
                if (self.chartStartDate != null || self.chartEndDate != null) {
                    let tempsdate = self.chartStartDate.setHours(0);
                    self.chartStartDate.setMinutes(0);
                    self.chartStartDate.setSeconds(0);
                    startyear = moment(tempsdate)
                        .add(1, "day")
                        .utc()
                        .format("DD/MM/YYYY");
                    let tempedate = self.chartEndDate.setHours(0);
                    self.chartEndDate.setMinutes(0);
                    self.chartEndDate.setSeconds(0);
                    endyear = moment(tempedate).utc().format("DD/MM/YYYY");
                }
                let url = `${self.url}BusinessType/GetBusinessTypeCompanies?`;
                if (startyear != null) {
                    url += `&startDate=${startyear}`;
                }
                if (endyear != null) {
                    url += `&endDate=${endyear}`;
                }

                self.LoadingDialog = true;
                axios.get(url).then(function (response) {
                    if (response.data.data == 0 || response.data.data == null) {
                        self.allBusinessList = [];
                    }
                    const theList = response.data.data;
                    self.allBusinessList = theList;
                    let num = 1;
                    for (let s = 0; s < theList.length; s++) {
                        if (theList[s].companyList != null) {
                            for (let t = 0; t < theList[s].companyList.length; t++) {
                                self.companyBusinessList.push({
                                    ...theList[s].companyList[t],
                                    no: num
                                })
                                num++;
                            }
                        }
                        dshow.push(theList[s].quantity);
                    }
                    var b = dshow.reverse();
                    self.chartSeries[0].data = b;
                    self.chartOptions.xaxis.categories = self.label;
                    self.LoadingDialog = false;
                });
            } catch (error) {
                alert("error");
                self.LoadingDialog = false;
            }
        },
        changeLangtoThai(thetext) {
            let result = "";
            if (thetext == "Individual") {
                result = "บคคลธรรมดา";
            } else if (thetext == "Hotel / Resort") {
                result = "โรงแรม / รีสอร์ท";
            } else if (thetext == "Restaurant / Coffee shop / Beverage shop") {
                result = "ร้านอาหาร / ร้านกาแฟ / ร้านเครื่องดื่ม";
            } else if (thetext == "Factory / Production") {
                result = "โรงงาน / การผลิต";
            } else if (thetext == "Office") {
                result = "ออฟฟิต";
            } else if (thetext == "Merchandise shop/department store") {
                result = "ร้านขายสินค้า / ห้างสรรพสินค้า";
            } else if (thetext == "Gas Station / Petroleum / Chemical") {
                result = "ปั้มน้ำมัน / ปิโตรเลียม / เคมีภัณฑ์";
            } else if (thetext == "Security company") {
                result = "บริษัทรักษาความปลอดภัย";
            } else if (thetext == "Construction contractor / Architect company") {
                result = "รับเหมาก่อสร้าง / บริษัทสถาปนิค";
            } else if (thetext == "Transport company") {
                result = "บริษัทขนส่ง";
            } else if (thetext == "Hospital") {
                result = "โรงพยาบาล";
            } else if (thetext == "Clinic / Spa / Beauty / Hygiene / Fitness") {
                result = "คลินิค / สปา / เสริมความงาม / อนามัย / ฟิตเนส";
            } else if (thetext == "School / university / cram school") {
                result = "โรงเรียน / มหาวิทยาลัย / โรงเรียนกวดวิชา";
            } else if (thetext == "Technology / IT company") {
                result = "บริษัทเทคโนโลยี / ไอที";
            } else if (thetext == "Accounting firm / Employment agency") {
                result = "สำนักงานบัญชี / บริษัทจัดหางาน";
            } else if (thetext == "Organizer company") {
                result = "บริษัทออแกนไนเซอร์";
            } else if (thetext == "Marketing Company / Consulting Company") {
                result = "บริษัทการตลาด / บริษัทที่ปรึกษา";
            } else if (thetext == "Finance / Securities") {
                result = "ธุรกิจเงินทุน / หลักทรัพย์";
            } else if (thetext == "Insurance companies / financial institutions") {
                result = "บริษัทประกัน / สถาบันการเงิน";
            } else if (thetext == "Outsourcing / Freelance") {
                result = "เอ๊าท์ซอส /  ฟรีแลนซ์";
            } else if (thetext == "Personal care and pharmaceutical business") {
                result = "ธุรกิจของใช้ส่วนตัวและเวชภัณฑ์";
            } else if (thetext == "Car Showroom / Car Tent") {
                result = "โชว์รูมรถ / เต็นท์รถ";
            } else if (thetext == "Car Care / Garage") {
                result = "คาร์แคร์ / อู่รถ";
            } else if (thetext == "Dormitory / Condo / Village") {
                result = "หอพัก / คอนโด / หมู่บ้าน";
            } else if (thetext == "Association / Foundation / Shrine / Temple") {
                result = "สมาคม / มูลนิธิ / ศาลเจ้า / วัด";
            } else if (thetext == "Rental Company") {
                result = "บริษัทให้เช่า";
            } else if (thetext == "Service") {
                result = "ราชการ";
            } else if (thetext == "Tourism and recreation") {
                result = "การท่องเที่ยวและนันทนาการ";
            } else if (thetext == "Transport and logistics") {
                result = "ขนส่งและโลจิสติกส์";
            } else if (thetext == "Other") {
                result = "อื่นๆ";
            }
            return result;
        },
        changetoexport(v) {
            let self = this;
            self.LoadingDialog = true;
            if (v == 1) {
                // Excel
                if (self.theralList.length == 0) {
                    for (let o = 0; o < self.allBusinessList.length; o++) {
                        if (self.allBusinessList[o].quantity != 0) {
                            self.theralList.push({ ...self.allBusinessList[o] });
                        }
                    }
                }
                let hasData = false;
                let wb = XLSX.utils.book_new();
                let wsdata = [];

                for (let sheet = 0; sheet < self.theralList.length; sheet++) {
                    wsdata = [];
                    let tempsheetname = self.theralList[sheet].businessTypeName;
                    let showtempsheetname = self.theralList[sheet].businessTypeName;
                    let thename = self.theralList[sheet].businessTypeName;
                    if (thename.includes("/")) {
                        if (localStorage.Templang == "ประเทศไทย") {
                            tempsheetname = self.changeLangtoThai(thename);
                        }
                        tempsheetname = tempsheetname.split("/")[0];
                    } else {
                        if (localStorage.Templang == "ประเทศไทย") {
                            tempsheetname = self.changeLangtoThai(thename);
                        } else {
                            tempsheetname = thename;
                        }

                    }
                    if (localStorage.Templang == "ประเทศไทย") {
                        showtempsheetname = self.changeLangtoThai(showtempsheetname);
                    }

                    let merges = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }, { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },];

                    wsdata.push("");
                    wsdata.push("");

                    // Add the third row with header labels
                    let headerRow = ["No", "Business Type", "Company Name", "Register Date", "Company Size"];
                    let thaiheaderRow = ["เลขที่", "ประเภทธุรกิจ", "ชื่อ บริษัท", "วันที่สมัคร", "จำนวนพนักงานทั้งหมด"];
                    //wsdata.push(headerRow);


                    if (self.theralList[sheet].companyList.length) {
                        for (let j = 0; j < self.theralList[sheet].companyList.length; j++) {
                            if (localStorage.Templang == "ประเทศไทย") {
                                let rowData = {
                                    ["เลขที่"]: self.theralList[sheet].companyList[j].no,
                                    ["ประเภทธุรกิจ"]: self.theralList[sheet].companyList[j].businessTypeName,
                                    ["ชื่อ บริษัท"]: self.theralList[sheet].companyList[j].companyName,
                                    ["วันที่สมัคร"]: self.DateFormat(self.theralList[sheet].companyList[j].register_Date),
                                    ["จำนวนพนักงานทั้งหมด"]: self.theralList[sheet].companyList[j].companySize,
                                };
                                wsdata.push(Object.values(rowData));
                            } else {

                                let rowData = {
                                    ["No"]: self.theralList[sheet].companyList[j].no,
                                    ["Business Type"]: self.theralList[sheet].companyList[j].businessTypeName,
                                    ["Company Name"]: self.theralList[sheet].companyList[j].companyName,
                                    ["Register Date"]: self.DateFormat(self.theralList[sheet].companyList[j].register_Date),
                                    ["Company Size"]: self.theralList[sheet].companyList[j].companySize,
                                };
                                wsdata.push(Object.values(rowData));
                            }
                        }
                        //var ws = XLSX.utils.json_to_sheet(wsdata, {cellDates:true});
                        var ws = XLSX.utils.json_to_sheet(wsdata);

                        let cellStyleA1 = {
                            t: "s",
                            v: "Report Summarizing number of subscriptions by business type.",
                            s: {
                                fill: { fgColor: { rgb: "grey" } },
                                alignment: { horizontal: "center", vertical: "center" },
                            },
                        };
                        let cellStyleA2 = {
                            t: "s",
                            v: "Business Type: " + showtempsheetname,
                            s: {
                                fill: { fgColor: { rgb: "grey" } },
                            },
                        };

                        ws["!merges"] = merges;

                        if (localStorage.Templang == "ประเทศไทย") {
                            ws["A1"] = { t: "s", v: "รายงานสรุปผลจำนวนการสมัครใช้งานจำแนกตามประเภทธุรกิจ", s: { r: 0, c: 0, fill: { fgColor: { rgb: "yellow" } }, alignment: { horizontal: "center", vertical: "center" } }, e: { r: 0, c: 5 } };
                            ws["A2"] = { t: "s", v: "ประเภทธุรกิจ: " + showtempsheetname, s: { r: 0, c: 0 }, e: { r: 0, c: 5 } };
                            ws["A3"] = { t: "s", v: thaiheaderRow[0], s: { font: { bold: true }, alignment: { horizontal: "center" }, fill: { fgColor: { rgb: "yellow" } } } };
                            ws["B3"] = { t: "s", v: thaiheaderRow[1], s: { font: { bold: true }, alignment: { horizontal: "center" } } };
                            ws["C3"] = { t: "s", v: thaiheaderRow[2], s: { font: { bold: true }, alignment: { horizontal: "center" } } };
                            ws["D3"] = { t: "s", v: thaiheaderRow[3], s: { font: { bold: true }, alignment: { horizontal: "center" } } };
                            ws["E3"] = { t: "s", v: thaiheaderRow[4], s: { font: { bold: true }, alignment: { horizontal: "center" } } };
                        } else {
                            // ws["A1"] = { t: "s", v: "Report Summarizing number of subscriptions by business type.",  s: { r: 0, c: 0,fill: { fgColor: { rgb: "yellow" } }, alignment: { horizontal: "center",vertical: "center" } }, e: { r: 0, c: 5 } };
                            // ws["A2"] = { t: "s", v: "Business Type: "+ showtempsheetname,  s: { r: 0, c: 0 }, e: { r: 0, c: 5 } };
                            let mergeA1 = ws["!merges"][0];
                            let rangeA1 = { s: { r: mergeA1.s.r, c: mergeA1.s.c }, e: { r: mergeA1.e.r, c: mergeA1.e.c } };
                            ws[XLSX.utils.encode_cell(rangeA1.s)] = cellStyleA1;

                            // Set the styles for individual cells in the merged range A2
                            let mergeA2 = ws["!merges"][1];
                            let rangeA2 = { s: { r: mergeA2.s.r, c: mergeA2.s.c }, e: { r: mergeA2.e.r, c: mergeA2.e.c } };
                            ws[XLSX.utils.encode_cell(rangeA2.s)] = cellStyleA2;

                            ws["A3"] = { t: "s", v: headerRow[0], s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" }, fill: { fgColor: { rgb: "yellow" } } } };
                            ws["B3"] = { t: "s", v: headerRow[1], s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } };
                            ws["C3"] = { t: "s", v: headerRow[2], s: { font: { bold: true }, alignment: { horizontal: "center" } } };
                            ws["D3"] = { t: "s", v: headerRow[3], s: { font: { bold: true }, alignment: { horizontal: "center" } } };
                            ws["E3"] = { t: "s", v: headerRow[4], s: { font: { bold: true }, alignment: { horizontal: "center" } } };
                        }
                        ws["!cols"] = [
                            { wch: 20 },
                            { wch: 35 },
                            { wch: 30 },
                            { wch: 20 },
                            { wch: 20 },
                            { wch: 20 },
                            { wch: 20 },
                            { wch: 20 },
                            { wch: 20 },
                            { wch: 20 },
                            { wch: 20 },
                        ];
                        ws['!rows'] = [
                            { hpt : 18},
                            { hpt : 18}, 
                        ]; 
                        XLSX.utils.book_append_sheet(wb, ws, tempsheetname);
                        hasData = true;
                    }
                }

                if (!hasData) {
                    alert("Your selected date range doesn't have Data!");
                }

                if (hasData) {
                    let exportExcelName = `Business Type Subscriptions Report.xlsx`;
                    self.LoadingDialog = false;
                    XLSX.writeFile(wb, exportExcelName);
                }

            } else if (v == 2) {
                // PDF
                if (self.theralList.length == 0) {
                    for (let o = 0; o < self.allBusinessList.length; o++) {
                        if (self.allBusinessList[o].quantity != 0) {
                            self.theralList.push({ ...self.allBusinessList[o] });
                        }
                    }
                }
                self.printDialog = true;
                self.todaydate = new Date();
                self.itemsPerPage1 = 500;
                self.LoadingDialog = false;
            }
        },
        downloadPDF() {
            html2canvas($(".canvas_div_pdf")[0], {
                allowTaint: true,
                useCORS: true,
            }).then(function (canvas) {
                var imgWidth = 210;
                var pageHeight = 290;
                var imgHeight = (canvas.height * imgWidth) / canvas.width;
                var heightLeft = imgHeight;

                var doc = new jsPDF("p", "mm");
                var position = 0;
                var pageData = canvas.toDataURL("image/jpeg", 1.0);
                var imgData = encodeURIComponent(pageData);
                doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                doc.setLineWidth(5);
                doc.setDrawColor(255, 255, 255);
                doc.rect(0, 0, 210, 295);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                    doc.setLineWidth(5);
                    doc.setDrawColor(255, 255, 255);
                    doc.rect(0, 0, 210, 295);
                    heightLeft -= pageHeight;
                }
                doc.save("Business Type Subscriptions Report.pdf");
            });
        },
        DateFormat(value) {
            let output = "-";
            let temp = new Date(value);
            try {
                let day = temp.getDate().toString().padStart(2, 0);
                let month = (temp.getMonth() + 1).toString().padStart(2, 0);
                let year = temp.getFullYear();
                output = `${day}/${month}/${year}`;
                return output;
            } catch (err) {
                return output;
            }
        },
        DateFormatonTop(value) {
            let output = "-";
            let temp = new Date(value);
            try {
                let day = temp.getDate().toString().padStart(2, 0);
                let month = (temp.getMonth() + 1).toString().padStart(2, 0);
                let year = temp.getFullYear();
                output = `${month}/${day}/${year}`;
                return output;
            } catch (err) {
                return output;
            }
        },
        DateFormatonTopbet(value) {
            let output = "-";
            let temp = new Date(value);
            try {
                if (value != null) {
                    let day = temp.getDate().toString().padStart(2, 0);
                    let month = (temp.getMonth() + 1).toString().padStart(2, 0);
                    let year = temp.getFullYear();
                    output = `${day}/${month}/${year}`;
                }
                return output;
            } catch (err) {
                return output;
            }
        },
    },
};
</script>
<style scoped>
/* >>>.mx-input {
    color: #f99d20;
    border: 2px solid #f99d20 !important;
} */

::v-deep.theme--light.v-data-table thead tr th {
    color: #f99d20;
    font-weight: normal;
    font-size: 13px;
}

.backGG {
    /* background-color: #f8f8f8; */
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0px 2px 6px #00000029;
    opacity: 1;
}

.backGGpdf {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-bottom: 1rem;
    opacity: 1;
}

.thechartstyle {
    min-width: 400px;
    max-width: 100%;
}

.linecht {
    height: 368px;
    width: 800px;
    position: "relative";
}

.ddl {
    float: right;
}

.container {
    display: flex;
    justify-content: center;
    height: 350px;
    width: 930px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 3rem;
    opacity: 1;
}

::-webkit-scrollbar {
    width: 6px;
    height: 0px;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #f99d20;
    font-weight: normal;
    font-size: 13px;
}

::v-deep.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
    border: unset;
}

::v-deep.v-data-table::-webkit-scrollbar {
    width: 6px;
    background-color: none;
    /* You can add other properties & values */
}

::v-deep .v-text-field__details{
    display: none;
}

/* >>>.v-data-table::-webkit-scrollbar-thumb {
  background: #707070;
  outline: 1px solid #654321;
 
} */
.print-break-page {
    page-break-after: always;
}

.ggg {
    margin-left: 30px;
}

.chartbox1 {
    width: 500px;
    height: 340px;

    background-color: #f8f8f8;
}

@media print {
    body {
        overflow: auto;
        height: auto;
    }

    .scroll-y {
        height: auto;
        overflow: visible;
    }
}

@media only screen and (max-width: 850px) {
    .linecht {
        width: 200px !important;
    }

    .ddl {
        float: left;
    }
}

@media (min-width: 768px) and (max-width: 1400px) {
    .linecht {
        width: 600px !important;
    }

    .ddl {
        float: right;
    }
}

@media (max-width: 860px) {
    .ggg {
        margin-left: 0px;
    }
}

@media (min-width: 950px) and (max-width: 1200px) {
    .ggg {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1900px) {
    .movelf {
        margin-right: 2rem;
    }
}
</style>