<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="background-color: #f0f0f7">
      <p style="margin-left: 4px; margin-top: 22px" class="headertext">
        {{ $t("HelpandFeedback") }}
      </p>
    </div>

    <v-card class="dialogmargin" style="margin-bottom: 5rem">
      <div class="mt-3">
        <v-row>
          <v-col cols="7" xs="3" sm="6" md="4" lg="5" xl="4">
            <v-text-field
              class="ml-5"
              color="#F99D20"
              autocomplete="off"
              v-model="searchtxt"
              outlined
              dense
              style="margin-top: 1.5rem; color: black"
              :placeholder="$t('SearchforFeedback')"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              color="#F99D20"
              @click="Search"
              class="white--text text-capitalize mt-6 lock"
              width="120"
              max-height="35"
            >
              <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-data-table
        style="margin-left: 10px; margin-right: 20px; min-height: 200px"
        class="mytable"
        :items="FeebackList"
        :headers="headers"
        :page.sync="page"
        hide-default-header
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        hide-default-footer
      >
        <template v-slot:header>
          <thead>
            <tr>
              <th class="head b-text" style="color: #f99d20">
                {{ headers[0].text }}
              </th>
              <th class="head b-text" style="color: #f99d20">
                {{ headers[1].text }}
              </th>
              <th class="head b-text" style="color: #f99d20">
                {{ headers[2].text }}
              </th>

              <th class="head b-text" style="color: #f99d20">
                {{ headers[3].text }}
              </th>
              <th class="head b-text" style="color: #f99d20">
                {{ headers[4].text }}
              </th>
              <th class="action b-text" style="color: #f99d20">
                {{ $t("action") }}
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.feedback_No }}</td>
            <td>{{ item.company_Name }}</td>
            <td>{{ item.employee_Name }}</td>
            <td>{{ item.feedback_Subject }}</td>
            <td class="text-truncate" style="max-width: 130px">
              {{ item.feedback_Message }}
            </td>
            <td class="pl-2">
              <v-btn icon @click="DetailsFeedback(item)">
                <img :src="images.edit" />
              </v-btn>
              <!-- <v-btn icon @click="Deletedia(item.feedback_ID)">
                <img :src="images.delete" />
              </v-btn> -->
            </td>
          </tr>
        </template>
      </v-data-table>
      <div>
        <CustomPaginationComponent
          :total-items="FeebackList.length"
          :page.sync="page"
          :itemsPerPage.sync="itemsPerPage"
          :showPageText="false"/>
      </div>
    </v-card>
    
    <!-- DetailDialog -->
    <v-dialog v-model="DetailDialog" persistent max-width="700">
      <v-card style="border-radius: 15px">
        <v-row class="ml-5 mr-5">
          <p style="font-size: 22px" class="mt-5">
            {{ $t("Helpandfeedbackdetail") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn @click="Close" icon class="mt-5">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-row>
        <!-- <p class="pt-5 ml-8 mr-2" style="font-size: 22px">
          Help And Feedback Details
        </p> -->
        <v-card-text>
          <v-container style="margin-top: -1.3rem" class="container">
            <v-row>
              <v-col class="mydiv" cols="3">
                <div style="margin-left: 0.5rem">
                  <p class="myfont mt-2">{{ $t("FeedbackNo") }}</p>
                </div>
              </v-col>
              <v-col class="mydiv" cols="8">
                <v-text-field
                  color="#F99D20"
                  readonly
                  dense
                  v-model="details.feedbackno"
                  autocomplete="off"
                  class="ml-1 ml-md-0"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -1.7rem">
              <v-col class="mydiv" cols="3">
                <div style="margin-left: 0.5rem">
                  <p class="myfont mt-2">{{ $t("Subject") }}</p>
                </div>
              </v-col>
              <v-col class="mydiv" cols="8">
                <v-text-field
                  color="#F99D20"
                  readonly
                  v-model="details.subject"
                  autocomplete="off"
                  dense
                  class="ml-1 ml-md-0"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -1.7rem">
              <v-col class="mydiv" cols="3">
                <div style="margin-left: 0.5rem">
                  <p class="myfont mt-2">{{ $t("companies") }}</p>
                </div>
              </v-col>
              <v-col class="mydiv" cols="8">
                <v-text-field
                  color="#F99D20"
                  readonly
                  v-model="details.company"
                  autocomplete="off"
                  dense
                  class="ml-1 ml-md-0"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -1.3rem">
              <v-col class="mydiv" cols="12">
                <div class="chatroom">
                  <div
                    style="
                      height: 40vh;
                      overflow: auto;
                      background-color: #f5ede1;
                      border-top-left-radius: 14px;
                      border-top-right-radius: 14px;
                    "
                  >
                    <ul v-for="(item, i) in messages" :key="i">
                      <li :style="item.company_ID != 0 ? Mee : Otherr">
                        <v-card-text
                          style="font-size: 12px; font-weight: 100"
                          v-text="
                            item.company_ID != 0 ? item.employee_Name : 'Admin'
                          "
                          :style="item.company_ID != 0 ? MyName : OtherName"
                        ></v-card-text>
                        <v-card
                          :style="item.company_ID != 0 ? Me : Other"
                          :class="
                            item.company_ID != 0
                              ? 'bubble mee'
                              : 'bubble otherr'
                          "
                        >
                          <div class="justify-space-between">
                            <v-card-title
                              class="d-flex flex-no-wrap"
                              v-html="item.chatMessage.replace(/\n/g, '<br/>')"
                              style="font-size: 13px; font-weight: 300"
                            ></v-card-title>
                            <v-card-text
                              v-text="
                                convertUTCDateToLocalDate(item.createDate)
                              "
                              style="font-size: 10px; margin-top: -0.5rem"
                            ></v-card-text>
                          </div>
                        </v-card>
                      </li>
                    </ul>
                  </div>
                  <v-textarea
                    class="flex-shrink-1 ml-1 mr-1 send"
                    dense
                    :placeholder="$t('Typemessage')"
                    type="text"
                    auto-grow
                    v-model="userMessage"
                    style="font-size: 12px; max-height: 80px; overflow: auto"
                    rows="1"
                  >
                    <v-btn slot="append" icon @click="SendChatMessage"
                      ><img :src="images.send"
                    /></v-btn>
                  </v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <!-- <v-dialog v-model="DeleteDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("Deletehelpandfeedback") }}2
          </p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col align="center" cols="12">
              <p style="font-size: 16px; color: #444444" class="pt-9">
                {{ $t("ConfirmDelete") }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="DeleteDialog = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#FF2727"
              class="mr-2 white--text text-capitalize"
              width="120"
              max-height="35"
              @click="DeleteFeedback"
              >{{ $t("delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="chatloadingdialog"></LoadingDialog>
    
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
// import store from "@/store";
import LogTrace from "@/api/Function.js";
import CustomPaginationComponent from '../components/CustomPagination.vue';
import LoadingDialog from "@/components/Loading.vue";
export default {
  mixins: [LogTrace],
  components: { CustomPaginationComponent, LoadingDialog },
  data() {
    return {
      searchlength: 0,
      chatloadingdialog: false,
      Mee: {
        float: "right",
      },
      Otherr: {
        float: "left",
      },
      Me: {
        color: "#444444",
        float: "right",
        background: "#fff",
      },
      Other: {
        color: "#444444",
        background: " #fff",
        float: "left",
      },
      OtherName: {
        color: "#FAA91B",
      },
      MyName: {
        color: "#0FA7D8",
      },
      userMessage: "",
      messages: [],
      employee_ID: null,
      feedback_ID: null,
      page: 1,
      itemsPerPage: 10,
      details: {
        feedback_ID: null,
        company: null,
        feedbackno: null,
        subject: null,
        messages: null,
        employeename: null,
        employee_ID: null,
        createDate: null,
        company_ID: null,
      },
      url: enurl.apiUrl,
      FeebackList: [],
      DetailDialog: false,
      DeleteDialog: false,
      searchtxt: "",
      condition: "Replied",
      images: {
        edit: require("@/assets/images/reveal_icon.png"),
        delete: require("@/assets/images/Mask Group 177.png"),
        send: require("@/assets/images/send.png"),
      },
      tokenList: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("FeedbackNo"),
          value: "feedback_No",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("companies"),
          value: "company_Name",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("employee_name"),
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("Subject"),
          align: "left",
          sortable: true,
          filterable: true,
        },
        {
          text: this.$t("Messageforfeedback"),
          align: "left",
          sortable: true,
        },
        {
          text: "Active",
          value: "actions",
          sortable: true,
          align: "left",
        },
      ];
    },
  },
  watch: {
    $route() {
      this.GetFeedbackDetailsByWID();
    },

    async searchtxt() {
      if (this.searchlength == 1) 
      {
        await this.GetFeedbackList();
      }
    },
  },

  async mounted() {
    try
    {
      this.chatloadingdialog = true;
      await this.GetFeedbackList();
      await this.GetFeedbackDetailsByWID();
    }
    catch(error)
    {
      this.chatloadingdialog = false;
      alert(error)
    }
  },

  methods: {
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined)
      {
        self.searchlength = 1;
        let List = self.FeebackList;
        self.FeebackList = [];
        let temp = List.filter((v) => v.feedback_No.toLowerCase().indexOf(self.searchtxt.toLowerCase()) > -1 || v.company_Name.toLowerCase().indexOf(self.searchtxt.toLowerCase()) > -1);
        for (let i = 0; i < temp.length; i++) 
        {
          self.FeebackList.push(temp[i]);
        }
      }
      else
      {
        alert("Please enter text");
        self.GetFeedbackList();
      }
    },

    async GetToken(UserID) {
      let self = this;
      let temps = {
        user_ID: UserID,
      };
      await axios.post(`${self.url}Firebasetoken/GetFirebaseTokenByUserID`, temps)
      .then(function (response) {
        if (response.data.data.length != 0) 
        {
          let temp = response.data.data.map((x) => x.fireBase_Token);
          self.tokenList = temp;
        }
      }).catch(function(error){
        alert(error);
        self.chatloadingdialog = false;
      });
    },

    Close() {
      let self = this;
      self.userMessage = "";
      self.messages = [];
      self.DetailDialog = false;
      self.$router
        .replace({
          name: "HelpAndFeedback",
        })
        .catch(() => {});
    },

    convertUTCDateToLocalDate(date) {
      date = new Date(date);
      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output = null;
      var hour = newDate.getHours() == 0 ? 12 : newDate.getHours() > 12 ? newDate.getHours() - 12 : newDate.getHours();
      var min = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
      var ampm = newDate.getHours() < 12 ? "AM" : "PM";
      output = hour + ":" + min + " " + ampm;
      return output;
    },

    Deletedia(id) {
      this.feedback_ID = id;
      this.DeleteDialog = true;
    },

    DeleteFeedback() {
      let self = this;
      self.chatloadingdialog = true;
      let temp = {
        feedback_ID: self.feedback_ID,
      };
      axios.post(`${self.url}HelpFeedback/DeleteFeedback`, temp)
      .then(function (response) {
        if (response.data.status == 0)
        {
          alert(response.data.message);
          self.DeleteDialog = false;
          self.chatloadingdialog = false;
          self.GetFeedbackList();
        }
      })
      .catch(function (error) {
        alert(error);
        self.chatloadingdialog = false;
      });
    },

    async DetailsFeedback(temp) {
      let self = this;
      try
      {
        self.chatloadingdialog = true;
        self.employee_ID = temp.employee_ID;
        self.details.feedback_ID = temp.feedback_ID;
        self.details.company = temp.company_Name;
        self.details.feedbackno = temp.feedback_No;
        self.details.subject = temp.feedback_Subject;
        self.details.messages = temp.feedback_Message;
        self.details.createDate = temp.createDate;
        self.details.employee_ID = temp.employee_ID;
        self.details.employeename = temp.employee_Name;
        self.details.company_ID = temp.company_ID;
        await self.GetChatMessages(temp.feedback_ID);
        await self.GetToken(temp.employee_ID);
        self.DetailDialog = true;
        self.chatloadingdialog = false;
      }
      catch(error)
      {
        alert(error);
        self.chatloadingdialog = false;
      }
    },

    async GetFeedbackDetailsByWID() {
      try 
      {
        let self = this;
        if (self.$route.params.condition != "Replied") 
        {
          let notiSring = parseInt(self.$route.params.notiString);
          if (notiSring) 
          {
            let temp = {
              wF_ID: notiSring,
            };
            const response = await axios.post(`${self.url}HelpFeedback/GetFeedbackByWorkFlow`,temp);
            let tempdetail = response.data.data[0];
            self.employee_ID = tempdetail.employee_ID;
            self.details.feedback_ID = tempdetail.feedback_ID;
            self.details.company = tempdetail.company_Name;
            self.details.feedbackno = tempdetail.feedback_No;
            self.details.subject = tempdetail.feedback_Subject;
            self.details.messages = tempdetail.feedback_Message;
            self.details.createDate = tempdetail.createDate;
            self.details.employee_ID = tempdetail.employee_ID;
            self.details.employeename = tempdetail.employee_Name;
            self.details.company_ID = tempdetail.company_ID;
            await self.GetChatMessages(tempdetail.feedback_ID);
            await self.GetToken(tempdetail.employee_ID);
            self.DetailDialog = true;
          }
        } 
        else 
        {
          let notiSring = parseInt(self.$route.params.notiString);
          if (notiSring) 
          {
            let temp = {
              feedback_ID: notiSring,
            };
            const response = await axios.post(`${self.url}HelpFeedback/GetFeedbackByFeedBack`,temp);
            let tempdetail = response.data.data[0];
            self.employee_ID = tempdetail.employee_ID;
            self.details.feedback_ID = tempdetail.feedback_ID;
            self.details.company = tempdetail.company_Name;
            self.details.feedbackno = tempdetail.feedback_No;
            self.details.subject = tempdetail.feedback_Subject;
            self.details.messages = tempdetail.feedback_Message;
            self.details.createDate = tempdetail.createDate;
            self.details.employee_ID = tempdetail.employee_ID;
            self.details.employeename = tempdetail.employee_Name;
            self.details.company_ID = tempdetail.company_ID;
            await self.GetChatMessages(tempdetail.feedback_ID);
            await self.GetToken(tempdetail.employee_ID);
            self.DetailDialog = true;
          } 
        }
      } 
      catch (error) 
      {
        self.chatloadingdialog = false;
        alert(error);
      }
    },

    async GetFeedbackList() {
      try 
      {
        let self = this;
        self.searchlength = 0;
        await axios.get(`${self.url}HelpFeedback/GetAllFeedbacks`)
        .then(function (response) {
          self.chatloadingdialog = false;
          if(response.data.status == 0)
          {
            self.FeebackList = response.data.data;
          }
          else
          {
            alert(response.data.message)
          }
        });
      } 
      catch (error) 
      {
        self.LogTrace(error, "Get Feedback", 49, "Critical");
        self.chatloadingdialog = false;
      }
    },

    async GetChatMessages(FeedbackID) {
      try 
      {
        let self = this;
        let temp = {
          feedback_ID: FeedbackID,
        };
        await axios.post(`${self.url}FeedbackChatMessage/GetFeedbackMessages`, temp)
        .then(function (response) {
         if(response.data.status == 0)
         {
          let tempmessages = response.data.data;
          let temp = [];
          temp.push({
            employee_ID: self.details.employee_ID,
            admin_ID: 0,
            chatMessage: self.details.messages,
            feedback_ID: FeedbackID,
            company_ID: self.details.company_ID,
            employee_Name: self.details.employeename,
            user_Name: self.userName,
            createDate: self.details.createDate,
          });
          self.messages = [...temp, ...tempmessages];
         }
        }).catch(function(error){
          alert(error);
          self.chatloadingdialog = false;
        });
      } 
      catch (error) 
      {
        self.chatloadingdialog = false;
        alert(error);
      }
    },

    async getLatestData() {
      let self = this;
      let id = sessionStorage.employee_ID;
      let temp = {
        employee_ID: parseInt(id),
      };
      await axios.post(`${self.url}HelpFeedback/GetWorkflowByProductAdmin`, temp)
      .then(function (response) {
        if (response.data.status == 0)
        {
          let WF_ID = response.data.data.wF_ID;
          let senderID = response.data.data.requester_ID;
          let recipientID = response.data.data.approver_ID;
          self.newnotiList(WF_ID, senderID, recipientID);
        }
      });
    },

    async newnotiList(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: recipientID,
        recipientID: senderID,
        seen: false,
        delicated: false,
        description: `has replied to your feedback`,
      };
      await axios.post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
      .then(function (response) {
        if (response.data.status == 0) {
          self.pushNotification(senderID); //senderID = recipientId
        }
      });
    },

    async pushNotification(recipientID) {
      let self = this;
      let noti_temp = {
        registerIDs: self.tokenList,
        title: "Feedback",
        body: "Admin" + "has replied to your feedback",
        reportID: recipientID,
      };
      await axios.post(`${this.url}Firebasetoken/sendNotification`, noti_temp)
        .then(function () {
          //console.log(response.data);
        });
    },

    SendChatMessage() {
      let self = this;
      self.chatloadingdialog = true;
      if (self.userMessage != null && self.userMessage != "") 
      {
        let temp = {
          sender_ID: self.employee_ID,
          receiver_ID: 0,
          chatMessage: self.userMessage,
          feedback_ID: self.details.feedback_ID,
          company_ID: 0,
        };
        axios.post(`${self.url}FeedbackChatMessage/SendFeedbackMessages`, temp)
        .then(async function (response) {
          if (response.data.status == 0)
          {
            //self.LogTrace(null, "Add Feedback", 49, "Low");
            await self.getLatestData();
            self.chatloadingdialog = false;
            await self.GetChatMessages(self.details.feedback_ID);
            self.userMessage = "";
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add Feedback", 49, "Low");
          alert(error);
          self.chatloadingdialog = false;
        });
      } 
      else 
      {
        alert("Type Message");
        self.chatloadingdialog = false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep.v-data-table::-webkit-scrollbar {
  width: 6px;
  background-color: none;
  /* You can add other properties & values */
}

::v-deep.v-data-table::-webkit-scrollbar-thumb {
  background: #707070;
  outline: 1px solid #654321;
  /* You can add other properties & values */
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

.head {
  color: #f99d20;
  cursor: pointer;
  font-weight: 400;
  min-width: 180px !important;
  font-size: 16px !important;
}
.action {
  color: #f99d20;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px !important;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block !important;
  clear: both;
  padding: 10px;
  border-radius: 30px;
  margin-bottom: -20px;
}

.v-card__subtitle,
.v-card__title,
.v-card__text {
  line-height: 1.2rem !important;
  /* padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 7px !important;
  padding-bottom: 4px !important; */
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.mytitle {
  font-size: 18px;
  font-weight: 500;
}

.delbtn {
  background-color: #ff6565;
  border-radius: 20px;
}

::v-deep.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

::v-deep.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

/* .v-card:not(.v-sheet--tile):not(.v-card--shaped){
  border-radius: 20px;
} */

.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}

/* ::v-deep .theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
} */

::v-deep.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

::v-deep.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

::v-deep.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

::v-deep.v-data-table tr td {
  height: 70px;
}

::v-deep.theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}

::v-deep.theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.firsttab {
  background-color: white;
  width: 140px;
}

.tabone {
  width: 250px;
  border: solid thin #f99d20;
}

::v-deep.mdi-paperclip::before {
  content: none;
}

::v-deep.foricon .mdi:before {
  color: #f99d20;
}

::v-deep.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f89d1f !important;
  border-radius: 10px 10px 5px 5px !important;
}

::v-deep.v-tabs .v-tab {
  border-radius: 10px 10px 5px 5px !important;
}

::v-deep.v-tabs-slider {
  background-color: #f0f0f7;
  height: 0;
  width: 0;
}

.title {
  font-size: 20px;
}
</style>

<style >